import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      viewBox="0 0 48 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <svg
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        style={{
          enableBackground: "new 0 0 512 512",
        }}
        xmlSpace="preserve"
        {...props}
      >
        <g>
          <g>
            <polygon points="291.838,35.838 263.57,64.106 435.474,236.011 0,236.011 0,275.989 435.474,275.989 263.57,447.894  291.838,476.162 512,256  " />
          </g>
        </g>
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
        <g />
      </svg>
    </svg>
  )
}

export default SvgComponent;
