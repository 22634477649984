import React from 'react';

export default function Link({url, size, styles, children}) {
  return (
    <a
      href={url}
      className={`link link__${size} ${styles}`}
      target="_blank"
      rel="noreferrer">
      {children}
    </a>
  )
}
