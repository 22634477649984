import React from 'react';

export default function Title({font, size, styles, type, textAlign, children}) {
  return (
    <div
      className={`${styles} title title__${size} title__${type} title__${textAlign} title__font__${font}`}>
      {children}
    </div>
  )
}
