import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10.99 19.86"
      {...props}
    >
      <defs>
        <style>
          {
            ".cls-chevron-right-active-1{fill:none;stroke:#28282b;stroke-miterlimit:10;stroke-width:1.5px;}"
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polyline className="cls-chevron-right-active-1" points="0.53 0.53 9.93 9.93 0.53 19.33" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent;
