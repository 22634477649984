import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.8 36.01" {...props}>
      <defs>
        <style>{".cls-vline-1{fill:#28282b;}"}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-vline-1"
            d="M0,0H2.8a2,2,0,0,1,2,2V34a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent;
