import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.77 18.77"
      {...props}
    >
      <defs>
        <style>
          {
            ".cls-wclose-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:1.5px;}"
          }
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <line className="cls-wclose-1" x1={2.53} y1={2.53} x2={15.24} y2={15.24} />
          <line className="cls-wclose-1" x1={15.24} y1={2.53} x2={2.53} y2={15.24} />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent;
