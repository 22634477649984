import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { BlackInfoIcon } from '../../assets/svgs';
import Label from '../../components/Label';

dayjs.extend(utc)
dayjs.extend(timezone)

export default function UpdateInfo({ date }) {
  if (!date || !dayjs(date).isValid()) {
    return null;
  }

  return (
    <Label size="medium" className="dashboard__update-info">
      <BlackInfoIcon className="icon__detail icon--text-right" />
      Updated <strong>{dayjs(date + 'Z').tz("EST").format('MMM D, YYYY [at] h:mm A')} EST</strong>. Updates every 4 hours.
    </Label>
  )
}
