import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 140.09 24.95"
      {...props}
    >
      <defs>
        <style>{".cls-logo{fill:#28282b;}"}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-logo"
            d="M32,3.29h5.67c3.5,0,6.06,1.16,6.06,4.87v.11a3.86,3.86,0,0,1-2.85,4c2.74.66,3.77,2.06,3.77,4.51v.1c0,3.56-2.42,5.28-6.19,5.28H32Zm5.67,8.22c2.76,0,3.82-1,3.82-3.32v-.1c0-2.14-1.24-3-3.88-3H34.25v6.43Zm.73,8.83c2.75,0,4-1.21,4-3.5v-.11c0-2.32-1.29-3.43-4.32-3.43H34.25v7Z"
          />
          <path
            className="cls-logo"
            d="M47.32,8.35h2.19v2.48a4.82,4.82,0,0,1,4.54-2.72v2.06c-2.77.13-4.54,1-4.54,4.4v7.57H47.32Z"
          />
          <path
            className="cls-logo"
            d="M55.05,18.31C55.05,15.12,58.42,14,62,14h2V13c0-2.19-.84-3.11-3-3.11-1.9,0-2.95.79-3.16,2.48H55.63c.29-3.14,2.79-4.3,5.46-4.3s5,1.08,5,4.93v9.1H63.93V20.4a5,5,0,0,1-4.29,2C57.08,22.37,55.05,21.13,55.05,18.31Zm8.88-1V15.6H62c-2.76,0-4.79.68-4.79,2.71,0,1.43.68,2.32,2.61,2.32C62.17,20.63,63.93,19.45,63.93,17.31Z"
          />
          <path
            className="cls-logo"
            d="M69.15,4A1.45,1.45,0,1,1,70.6,5.48,1.46,1.46,0,0,1,69.15,4Zm.4,4.32h2.19V22.14H69.55Z"
          />
          <path
            className="cls-logo"
            d="M75.3,8.35h2.18v2.19a4.76,4.76,0,0,1,4.43-2.43c2.85,0,4.85,1.56,4.85,5.56v8.47H84.57V13.52c0-2.43-1-3.51-3.24-3.51a3.63,3.63,0,0,0-3.85,3.77v8.36H75.3Z"
          />
          <path
            className="cls-logo"
            d="M90.61,18.68V10.19h-2V8.35h2V5.21H92.8V8.35H96v1.84H92.8v8.28c0,1.35.61,2,1.71,2a4.58,4.58,0,0,0,1.69-.29V22a5.16,5.16,0,0,1-1.87.29A3.36,3.36,0,0,1,90.61,18.68Z"
          />
          <path
            className="cls-logo"
            d="M98.57,8.35h2.19v2.48a4.82,4.82,0,0,1,4.54-2.72v2.06c-2.77.13-4.54,1-4.54,4.4v7.57H98.57Z"
          />
          <path
            className="cls-logo"
            d="M107.43,17.07V8.35h2.19V17c0,2.42,1,3.51,3.19,3.51a3.64,3.64,0,0,0,3.8-3.77V8.35h2.18V22.14h-2.18V20a4.73,4.73,0,0,1-4.38,2.42C109.49,22.37,107.43,20.87,107.43,17.07Z"
          />
          <path
            className="cls-logo"
            d="M121.17,17.89h2.13c.16,1.64,1,2.66,3.24,2.66s3-.71,3-2.18-1.08-1.93-3.22-2.32c-3.58-.66-4.79-1.66-4.79-4.06,0-2.56,2.5-3.88,4.66-3.88,2.48,0,4.62,1,5,4h-2.1c-.32-1.5-1.16-2.16-2.9-2.16s-2.61.79-2.61,2,.68,1.74,3.21,2.19c2.85.52,4.85,1.16,4.85,4.08,0,2.51-1.71,4.19-5.06,4.19S121.3,20.71,121.17,17.89Z"
          />
          <path
            className="cls-logo"
            d="M134.51,18.68V10.19h-2V8.35h2V5.21h2.18V8.35h3.22v1.84h-3.22v8.28c0,1.35.61,2,1.72,2a4.56,4.56,0,0,0,1.68-.29V22a5.12,5.12,0,0,1-1.87.29A3.35,3.35,0,0,1,134.51,18.68Z"
          />
          <path
            className="cls-logo"
            d="M13,18.64A6.17,6.17,0,1,0,9.88,7.13,6.19,6.19,0,0,0,7,10.88a6.09,6.09,0,0,0,.62,4.68A6.15,6.15,0,0,0,13,18.64ZM9,11.4a4.06,4.06,0,0,1,1.94-2.53A4.11,4.11,0,0,1,13,8.31,4.17,4.17,0,1,1,9,11.4Z"
          />
          <path
            className="cls-logo"
            d="M19.91,8.46a6,6,0,0,0,3.64,2.8,6.42,6.42,0,0,0,1.56.2,5.55,5.55,0,0,0,.82-.07,12.33,12.33,0,0,0-.31-1.93,3.93,3.93,0,0,1-1.56-.09A4,4,0,0,1,21.2,4.42,3.92,3.92,0,0,1,21.9,3a13.82,13.82,0,0,0-1.51-1.24,6,6,0,0,0-1.07,2.14A5.91,5.91,0,0,0,19.91,8.46Z"
          />
          <path
            className="cls-logo"
            d="M23.55,13.69a6,6,0,0,0-3.64,2.8A5.91,5.91,0,0,0,19.32,21a6,6,0,0,0,1.07,2.14,13.82,13.82,0,0,0,1.51-1.24,3.92,3.92,0,0,1-.7-1.4,4,4,0,0,1,2.86-5,4.33,4.33,0,0,1,1.56-.09,12.2,12.2,0,0,0,.31-1.92A6,6,0,0,0,23.55,13.69Z"
          />
          <path
            className="cls-logo"
            d="M13,22.44A4,4,0,0,1,16.69,25a13.07,13.07,0,0,0,1.83-.71,6,6,0,0,0-11.1,0A12.18,12.18,0,0,0,9.25,25,4,4,0,0,1,13,22.44Z"
          />
          <path
            className="cls-logo"
            d="M13,4.45A6,6,0,0,0,18.52.71,13.07,13.07,0,0,0,16.69,0,4,4,0,0,1,13,2.5,4,4,0,0,1,9.25,0,12.18,12.18,0,0,0,7.42.71,6,6,0,0,0,13,4.45Z"
          />
          <path
            className="cls-logo"
            d="M6,16.49a6,6,0,0,0-3.64-2.8A6,6,0,0,0,0,13.56a12.33,12.33,0,0,0,.31,1.93,2.94,2.94,0,0,1,.51-.05,4.27,4.27,0,0,1,1.06.13,4,4,0,0,1,2.45,1.89A4,4,0,0,1,4,21.93a13,13,0,0,0,1.52,1.24A6,6,0,0,0,6,16.49Z"
          />
          <path
            className="cls-logo"
            d="M2.38,11.26A6,6,0,0,0,6,8.46a6,6,0,0,0,.6-4.54A6,6,0,0,0,5.54,1.78,14.84,14.84,0,0,0,4,3a4,4,0,0,1,.71,1.4,4,4,0,0,1-.41,3.07A4,4,0,0,1,1.88,9.37a4,4,0,0,1-1.57.09A12.33,12.33,0,0,0,0,11.39a5.55,5.55,0,0,0,.82.07A6.42,6.42,0,0,0,2.38,11.26Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent;
