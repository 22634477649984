import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
        <path d="M9 12V9" stroke="#141417" strokeWidth="1.5" strokeLinecap="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9 5.25C8.58579 5.25 8.25 5.58579 8.25 6C8.25 6.41421 8.58579 6.75 9 6.75C9.41421 6.75 9.75 6.41421 9.75 6C9.75 5.58579 9.41421 5.25 9 5.25Z" fill="#141417"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="#141417" strokeWidth="1.5"/>
    </svg>
  )
}

export default SvgComponent;
