import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.79 16.15"
      {...props}
    >
      <defs>
        <style>{".cls-logo-icon-1{fill:currentColor;}"}</style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            className="cls-logo-icon-1"
            d="M8.4,12.07a3.9,3.9,0,0,0,2-.54,4,4,0,0,0-4-6.91,4,4,0,0,0-1.46,5.45A4,4,0,0,0,8.4,12.07ZM5.79,7.38a2.7,2.7,0,0,1,4.94-.65,2.69,2.69,0,1,1-4.94.65Z"
          />
          <path
            className="cls-logo-icon-1"
            d="M12.89,5.48a3.87,3.87,0,0,0,2.36,1.81,3.83,3.83,0,0,0,1,.13,4.73,4.73,0,0,0,.53,0,9.88,9.88,0,0,0-.2-1.25,2.71,2.71,0,0,1-1-.06,2.62,2.62,0,0,1-1.85-3.21A2.61,2.61,0,0,1,14.18,2a11,11,0,0,0-1-.8,3.85,3.85,0,0,0-.31,4.33Z"
          />
          <path
            className="cls-logo-icon-1"
            d="M15.25,8.86a3.89,3.89,0,0,0-2.75,4.76A3.92,3.92,0,0,0,13.2,15a9.49,9.49,0,0,0,1-.8,2.53,2.53,0,0,1-.46-.91,2.62,2.62,0,0,1,1.85-3.21,2.71,2.71,0,0,1,1-.06,10,10,0,0,0,.2-1.24A3.82,3.82,0,0,0,15.25,8.86Z"
          />
          <path
            className="cls-logo-icon-1"
            d="M8.39,14.53a2.6,2.6,0,0,1,2.41,1.62A8.14,8.14,0,0,0,12,15.69a3.88,3.88,0,0,0-7.19,0A8.14,8.14,0,0,0,6,16.15,2.59,2.59,0,0,1,8.39,14.53Z"
          />
          <path
            className="cls-logo-icon-1"
            d="M8.39,2.88A3.89,3.89,0,0,0,12,.46,8.14,8.14,0,0,0,10.8,0,2.6,2.6,0,0,1,8.39,1.62,2.59,2.59,0,0,1,6,0,8.14,8.14,0,0,0,4.8.46,3.88,3.88,0,0,0,8.39,2.88Z"
          />
          <path
            className="cls-logo-icon-1"
            d="M3.9,10.67A3.87,3.87,0,0,0,1.54,8.86,3.82,3.82,0,0,0,0,8.78,8.44,8.44,0,0,0,.2,10l.33,0a2.59,2.59,0,0,1,.68.09A2.61,2.61,0,0,1,2.8,11.3a2.6,2.6,0,0,1-.19,2.89,8.43,8.43,0,0,0,1,.81A3.9,3.9,0,0,0,3.9,10.67Z"
          />
          <path
            className="cls-logo-icon-1"
            d="M1.54,7.29A3.87,3.87,0,0,0,4.28,2.54a3.7,3.7,0,0,0-.69-1.39,11,11,0,0,0-1,.8,2.61,2.61,0,0,1,.46.91A2.63,2.63,0,0,1,1.21,6.07a2.64,2.64,0,0,1-1,.06A8.36,8.36,0,0,0,0,7.38a4.73,4.73,0,0,0,.53,0A3.83,3.83,0,0,0,1.54,7.29Z"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent;
